import React, { useMemo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Typist from 'react-typist'
import classnames from 'classnames'
import { useWindowSize } from '@reach/window-size'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from '@reach/router'

import CourseTag from '_components/course-tag'
import SectionTitle from '_components/landing-components/section-title'
import HeroLine from '_assets/icons/hero-line.svg'
import Button, { ButtonTheme } from '_components/landing-components/button'
import { prismicTitlePropType } from '_utils/proptypes'
import { MOBILE_THRESHOLD } from '_config/media-queries'
import { courseClassesSelector } from '_modules/public-courses/selectors'
import { formatPathname } from '_utils/location'
import { getSlugSelector, hasHighlightMessageSelector } from '_modules/prismic/selectors'
import { enrollRedirectUrl } from '_utils/enroll-course-redirect'
import { authTokenSelector, hasCompletedOnboardingSelector } from '_modules/user/selectors'
import { COURSE_TYPES_INFO } from '_constants/course'
import ScrollableButton from '_views/landing/scrollable-button'
import useScroll from '_hooks/use-window-scroll'
import Svg from '_components/svg'
import { getCategoryClassesTotalCountSelector } from '_modules/public-categories/selectors'
import { FLAG_NEW_LANDING_PAGES } from '_config/environment'
import usePrevious from '_hooks/use-previous'
import { getCategoryClassesTotalCount } from '_modules/public-categories/actions'

import styles from './styles.css'

const MOBILE_HEADER_SIZE = 72
const HEADER_SIZE = 80

const NEW_CLASSES_PATHS = {
  VISION_PLUS_GOALS: '/courses/vision-plus-goals',
  WHOLE_PROSPERITY: '/courses/whole-prosperity',
  INTEGRATED_LEADERSHIP: '/courses/integrated-leadership',
  POWER_MY_FUTURE: '/courses/power-my-future',
  LIGHTYEAR_COACH_TRAINING: '/courses/lightyear-coach-training',
  LEGADO_PERSONAL: '/courses/legado-personal',
  GET_THERE_NOW: '/courses/get-there-now',
  WORKSHOPS: '/courses/workshops',
}

const HeroSection = ({ slice, id, courseSlug }) => {
  const classes = useSelector(courseClassesSelector)
  const hasHighlightMessage = useSelector(hasHighlightMessageSelector)
  const { pathname } = useLocation()
  const scroll = useScroll()
  const dispatch = useDispatch()
  const [isScrollingUp, setIsScrollingUp] = useState(false)
  const lastScroll = usePrevious(scroll)

  useEffect(() => {
    if (lastScroll < scroll) {
      setIsScrollingUp(false)
    }

    if (scroll < lastScroll) {
      setIsScrollingUp(true)
    }
  }, [lastScroll, scroll])

  const isCoursesTypesPage = useMemo(
    () => Object.values(NEW_CLASSES_PATHS).includes(pathname),
    [pathname]
  )

  const coursePage = useMemo(() => {
    if (!isCoursesTypesPage) {
      return ''
    }

    return Object.values(COURSE_TYPES_INFO).find(course => course.path === pathname)?.name || ''
  }, [isCoursesTypesPage, pathname])

  const courseType = useMemo(() => {
    const [, course] = pathname.split('/courses/')
    if (!course) {
      return ''
    }

    return course.replace(/-/g, '_').toUpperCase()
  }, [pathname])

  const availableClasses = useSelector(state =>
    getCategoryClassesTotalCountSelector(state, COURSE_TYPES_INFO[courseType]?.name)
  )

  const authToken = useSelector(authTokenSelector)
  const hasUserCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)

  const currentView = useMemo(() => formatPathname(pathname), [pathname])
  const slug = useSelector(state => getSlugSelector(state, currentView))
  const { width } = useWindowSize()

  const [currentIndex, setCurrentIndex] = useState(0)

  const hasActionButton = useMemo(
    () =>
      slice.actionButton?.url ||
      slice?.actionButtonScrollToSlice ||
      (classes.length > 0 && courseSlug),
    [classes.length, courseSlug, slice]
  )

  const actionButtonLabel = useMemo(() => {
    if (slice.actionButton?.url) {
      return slice.actionButton?.label
    }

    return ''
  }, [slice.actionButton])

  const nextIndex = useMemo(() => {
    const listLength = slice.items?.length

    return currentIndex + 1 < listLength ? currentIndex + 1 : 0
  }, [currentIndex, slice.items])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(nextIndex)
    }, 5000)
    return () => clearInterval(interval)
  }, [nextIndex])

  const backgroundStyles = useMemo(() => {
    if (slice.backgroundImage?.thumbnail) {
      return {
        backgroundImage: `url(${slice.backgroundImage.thumbnail})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }
    }

    if (slice?.backgroundColor) {
      return {
        background: slice.backgroundColor,
      }
    }
    return {}
  }, [slice])

  const renderItems = useMemo(() => {
    if (slice.items.length === 1) {
      return <p className={styles.item}>{slice.items?.[0]} </p>
    }

    return slice.items.map(
      (item, index) =>
        index === currentIndex && (
          <Typist cursor={{ show: false }} key={item}>
            <p className={styles.item}>{item} </p>
          </Typist>
        )
    )
  }, [currentIndex, slice])

  const firstAvailableClassId = useMemo(
    () => classes?.[0]?.firstAvailableClass?.get('id'),
    [classes]
  )

  const redirectState = useMemo(
    () => ({
      redirectTo: enrollRedirectUrl({
        authToken,
        hasCompletedOnboarding: hasUserCompletedOnboarding,
        ...(firstAvailableClassId && { classId: firstAvailableClassId }),
      }),
    }),
    [authToken, firstAvailableClassId, hasUserCompletedOnboarding]
  )

  const handleScrollToSliceClick = useCallback(() => {
    const element = document.querySelector(
      `section[id^=${slice?.actionButtonScrollToSlice?.slice}]`
    )
    if (element) {
      const { top } = element.getBoundingClientRect()
      const headerSize = width <= MOBILE_THRESHOLD ? MOBILE_HEADER_SIZE : HEADER_SIZE
      const slicePosition = top + window.scrollY - headerSize
      window.scrollTo({
        top: slicePosition,
        behavior: 'smooth',
      })
    }
  }, [slice, width])

  const courseIcon = useMemo(() => {
    // TODO - refact when we decide to integrate the news landing pages in FE
    if (courseType && isCoursesTypesPage) {
      return COURSE_TYPES_INFO[courseType.toUpperCase()]?.icon
    }
    return null
  }, [courseType, isCoursesTypesPage])

  const availableClassesText = useMemo(
    () => `${availableClasses || 0} class${availableClasses > 0 ? 'es' : ''} available`,
    [availableClasses]
  )

  const renderActionButton = useMemo(() => {
    if (isCoursesTypesPage && !slice.actionButton?.url) {
      return (
        <ScrollableButton
          text={availableClassesText}
          onClick={handleScrollToSliceClick}
          className={classnames(styles['scrollable-button'], {
            [styles['scrollable-button-fixed']]: scroll > 20,
            [styles['scroll-animation']]: !isScrollingUp,
          })}
        />
      )
    }

    if (hasActionButton) {
      if (isCoursesTypesPage && slice.actionButton?.url) {
        return (
          <ScrollableButton
            text={actionButtonLabel}
            url={slice.actionButton.url}
            className={classnames(styles['scrollable-button'], {
              [styles['scrollable-button-fixed']]: scroll > 20 && !isScrollingUp,
            })}
          />
        )
      }

      if (slice.actionButton?.url) {
        return (
          <Button
            url={slice.actionButton.url}
            theme={ButtonTheme.MALIBU}
            className={styles['action-buttons']}
          >
            {actionButtonLabel}
          </Button>
        )
      }

      if (authToken && slug) {
        return (
          <Button
            url={`/course/${slug}`}
            className={styles['action-buttons']}
            state={redirectState}
          >
            {actionButtonLabel}
          </Button>
        )
      }

      return (
        <Button url="?sign-up" className={styles['action-buttons']} state={redirectState}>
          {actionButtonLabel}
        </Button>
      )
    }

    return null
  }, [
    isCoursesTypesPage,
    slice.actionButton,
    hasActionButton,
    availableClassesText,
    handleScrollToSliceClick,
    scroll,
    isScrollingUp,
    authToken,
    slug,
    redirectState,
    actionButtonLabel,
  ])

  useEffect(() => {
    if (isCoursesTypesPage) {
      dispatch(getCategoryClassesTotalCount({ names: coursePage }))
    }
  }, [coursePage, dispatch, isCoursesTypesPage])

  const handleCourseTag = useMemo(
    () =>
      COURSE_TYPES_INFO[courseType]?.courseTags.COURSE_PAGE
        ? COURSE_TYPES_INFO[courseType]?.courseTags.COURSE_PAGE
        : COURSE_TYPES_INFO[courseType]?.courseTags.GENERAL_TAGS,
    [courseType]
  )

  return (
    <section
      className={classnames(styles.section, { [styles['highlight-message']]: hasHighlightMessage })}
      style={backgroundStyles}
      id={id}
    >
      {courseIcon && isCoursesTypesPage && <Svg icon={HeroLine} className={styles['hero-line']} />}
      {courseIcon && <Svg icon={courseIcon} className={styles['class-icon']} />}

      {handleCourseTag?.length && isCoursesTypesPage && (
        <div className={styles['course-tag-wrapper']}>
          {handleCourseTag.map(tag => (
            <CourseTag key={tag} text={tag} theme="default" className={styles['course-tag']} />
          ))}
        </div>
      )}
      <div className={styles.content}>
        {slice.sectionTitle && (
          <SectionTitle
            title={slice.sectionTitle}
            boldClassName={classnames({
              [styles['bold-text']]: isCoursesTypesPage,
            })}
            className={classnames({
              [styles.title]: isCoursesTypesPage,
            })}
          />
        )}
        {slice.items?.length && (
          <div
            className={classnames(styles['item-container'], {
              [styles['single-item-container']]: slice.items.length === 1,
            })}
          >
            {renderItems}
          </div>
        )}
        {slice.sectionText && (
          <p
            className={classnames(styles['section-text'], {
              [styles['section-text-gray']]: FLAG_NEW_LANDING_PAGES,
            })}
          >
            {slice.sectionText}
          </p>
        )}
        {renderActionButton}
      </div>
    </section>
  )
}

HeroSection.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    actionButton: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
    actionButtonScrollToSlice: PropTypes.shape({
      slice: PropTypes.string,
      label: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.string),
    sectionText: PropTypes.string,
    backgroundImage: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
    backgroundColor: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  courseSlug: PropTypes.string,
}

HeroSection.defaultProps = {
  courseSlug: '',
}

export default React.memo(HeroSection)
